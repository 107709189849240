import React from "react";
import CaseStudiesSection from "../components/CaseStudiesSection";
import Footer from "../components/ui/Footer";
import Navbar from "../components/ui/Navbar";
import { cn } from "../utils/cn";

const CaseStudiesPage = () => {
  const caseStudiesRef = React.createRef<HTMLDivElement>();

  return (
    <div className="flex flex-col min-h-screen dark:bg-black bg-white">
      <Navbar />
      <div className="bg-white dark:bg-black py-20">
        <div
          className={cn(
            "w-full max-w-[1300px] mx-auto px-4 mobile:px-6 laptop:px-24 desktop:px-24 mb-8",
            "mobile:mt-16 tablet:mt-20 laptop:mt-24 desktop:mt-28"
          )}
        >
          <h1
            className={cn(
              "text-5xl laptop:text-7xl desktop:text-8xl font-bold text-black dark:text-white mb-4 text-center",
              "mobile:text-3xl mobile:mb-2"
            )}
          >
            Case Studies
          </h1>
          <p
            className={cn(
              "text-xl laptop:text-2xl desktop:text-2xl font-normal text-neutral-700 dark:text-neutral-200 text-center",
              "mobile:text-base"
            )}
          >
            Learn how Altrove helps companies de-risk their critical materials
            sourcing challenges.
          </p>
        </div>
        <CaseStudiesSection
          className={cn("mt-2", "px-4 mobile:px-6 laptop:px-24 desktop:px-24")}
          ref={caseStudiesRef}
        />
      </div>
      <Footer />
    </div>
  );
};

export default CaseStudiesPage;
