import React from "react";
import ExpandableContactForm from "../ExpandableContactForm";

interface RiskCardProps {
  title: string;
  content: string;
  isOpen: boolean;
  onToggle: () => void;
}

const RiskCard: React.FC<RiskCardProps> = ({ title, content, isOpen, onToggle }) => (
  <div className="bg-[#171717] rounded-lg shadow-md p-6 flex flex-col justify-between relative h-full">
    <div className="flex flex-col">
      <h3 className="text-lg laptop:text-xl desktop:text-xl font-bold text-white mb-4">{title}</h3>
      <p className="text-base laptop:text-base desktop:text-base font-normal text-neutral-300 dark:text-neutral-300 mb-4">
        {content}
      </p>
    </div>
    <ExpandableContactForm 
      riskTitle={title} 
      isOpen={isOpen} 
      onToggle={onToggle} 
    />
  </div>
);

export default RiskCard;