import { useState, useRef, useLayoutEffect, useCallback } from 'react';
import { Tab } from './types';

export const useTabbedContent = (tabs: Tab[]) => {
  const [activeTab, setActiveTab] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [tabIndicatorIndex, setTabIndicatorIndex] = useState(0);
  const [maxContentHeight, setMaxContentHeight] = useState(0);

  const handleTabClick = useCallback((index: number) => {
    setActiveTab(index);
    setTabIndicatorIndex(index);
    if (containerRef.current && tabRefs.current[index]) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const tabRect = tabRefs.current[index]!.getBoundingClientRect();
      const scrollLeft = tabRect.left - containerRect.left + containerRef.current.scrollLeft - (containerRect.width - tabRect.width) / 2;
      containerRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, []);

  const handlePrevTab = () => {
    if (tabIndicatorIndex > 0) {
      setActiveTab((prevTab) => prevTab - 1);
      setTabIndicatorIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextTab = () => {
    if (tabIndicatorIndex < tabs.length - 1) {
      setActiveTab((prevTab) => prevTab + 1);
      setTabIndicatorIndex((prevIndex) => prevIndex + 1);
    }
  };

  useLayoutEffect(() => {
    if (containerRef.current && tabRefs.current[activeTab]) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const tabRect = tabRefs.current[activeTab]!.getBoundingClientRect();
      const scrollLeft = tabRect.left - containerRect.left + containerRef.current.scrollLeft - (containerRect.width - tabRect.width) / 2;
      containerRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [activeTab]);

  useLayoutEffect(() => {
    let maxHeight = 0;
    tabs.forEach((tab) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = tab.content;
      document.body.appendChild(tempDiv);
      maxHeight = Math.max(maxHeight, tempDiv.offsetHeight);
      document.body.removeChild(tempDiv);
    });
    setMaxContentHeight(maxHeight);
  }, [tabs]);

  return {
    activeTab,
    tabIndicatorIndex,
    maxContentHeight,
    containerRef,
    tabRefs,
    handleTabClick,
    handlePrevTab,
    handleNextTab,
  };
};