export const tabs = [
  {
    title: "Direct Purchase",
    content: "Purchase directly from our vetted manufacturing partners.",
  },
  {
    title: "Scale-up Production",
    content: "Scale up production with your in-house facilities.",
  },
  {
    title: "Partner with Suppliers",
    content: "Put us in touch to partner with your trusted existing suppliers.",
  },
];
