import { forwardRef, useMemo } from "react";
import { useScreenSize } from "../../hooks/useScreenSize";
import { cn } from "../../utils/cn";
import DesignContent from "./DesignContent";

const DesignSection = forwardRef((_props, ref) => {
  const { isDesktop } = useScreenSize();

  const designContentMemo = useMemo(() => <DesignContent />, []);

  return (
    <div
      id="design-section"
      className="bg-white dark:bg-black py-12 mobile:py-8 laptop:py-20 px-4 mobile:px-6 laptop:px-24 desktop:px-24"
      ref={ref}
    >
      <div className="flex flex-col laptop:flex-row desktop:flex-row items-center justify-between">
        {designContentMemo}
        <div
          className={cn(
            "h-[275px] tablet:h-[375px] w-full laptop:w-1/2 desktop:w-1/2 mt-8 laptop:mt-0 laptop:ml-8 desktop:mt-0 desktop:ml-8 flex justify-end mobile:mt-8 mobile:mb-8 mobile:ml-0 mobile:mr-0 tablet:mt-8 tablet:mb-8 tablet:ml-0 tablet:mr-0",
            isDesktop ? "h-[550px]" : "h-[275px]"
          )}
        >
          <img
            src="https://createsiteai.b-cdn.net/static_website/altrove/white_mineral.png"
            alt="Design Mineral"
            className="w-full h-full object-contain scale-[0.8]"
          />
        </div>
      </div>
    </div>
  );
});

export default DesignSection;
