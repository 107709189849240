import React from 'react';
import { cn } from '../../utils/cn';
import CenteredTabContent from '../CenteredTabContent';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import TabButton from './TabButton';
import TabIndicator from './TabIndicator';
import { Tab } from './types';
import { useTabbedContent } from './useTabbedContent';

interface CenteredTabbedContentProps {
  tabs: Tab[];
  sectionName: string;
}

const CenteredTabbedContent: React.FC<CenteredTabbedContentProps> = ({ tabs, sectionName }) => {
  const {
    activeTab,
    tabIndicatorIndex,
    maxContentHeight,
    containerRef,
    tabRefs,
    handleTabClick,
    handlePrevTab,
    handleNextTab,
  } = useTabbedContent(tabs);

  return (
    <div className="bg-[#171717] rounded-lg shadow-md p-6 mobile:w-full tablet:w-full laptop:w-full desktop:w-full">
      <div
        className={cn(
          'flex justify-center border-b border-neutral-700 dark:border-neutral-700 mb-4 mobile:overflow-x-auto mobile:whitespace-nowrap',
          'scrollbar-thin',
          'scrollbar-thumb-[var(--scrollbar-thumb-color)]',
          'scrollbar-track-[var(--scrollbar-track-color)]',
          'scrollbar-thumb-rounded-[var(--scrollbar-thumb-radius)]',
          'scrollbar-track-rounded-[var(--scrollbar-track-radius)]',
          'scrollbar-w-[var(--scrollbar-width)]',
          'scrollbar-h-[var(--scrollbar-height)]'
        )}
        ref={containerRef}
      >
        <div className="flex justify-between w-full max-w-[600px]">
          {tabs.map((tab, index) => (
            <TabButton
              key={index}
              tab={tab}
              index={index}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              ref={(el) => (tabRefs.current[index] = el)}
              sectionName={sectionName}
            />
          ))}
        </div>
      </div>
      <CenteredTabContent content={tabs[activeTab].content} maxHeight={maxContentHeight} />
      <div className="flex justify-center items-center mt-4">
        <button
          className={cn(
            'text-neutral-500 dark:text-neutral-400 hover:text-white mr-2 cursor-pointer',
            tabIndicatorIndex === 0 ? 'invisible' : 'visible'
          )}
          onClick={handlePrevTab}
          aria-label="Previous Tab"
          data-umami-event={`${sectionName}_previous_tab`}
        >
          <FaAngleLeft />
        </button>
        {tabs.map((_, index) => (
          <TabIndicator
            key={index}
            index={index}
            tabIndicatorIndex={tabIndicatorIndex}
            handleTabClick={handleTabClick}
            sectionName={sectionName}
          />
        ))}
        <button
          className={cn(
            'text-neutral-500 dark:text-neutral-400 hover:text-white ml-2 cursor-pointer',
            tabIndicatorIndex === tabs.length - 1 ? 'invisible' : 'visible'
          )}
          onClick={handleNextTab}
          aria-label="Next Tab"
          data-umami-event={`${sectionName}_next_tab`}
        >
          <FaAngleRight />
        </button>
      </div>
    </div>
  );
};

export default CenteredTabbedContent;