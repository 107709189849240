import React from 'react';
import { cn } from '../../utils/cn';
import LazyImage from '../LazyImage';

const newsItems = [
  {
    logo: 'https://createsiteai.b-cdn.net/static_website/altrove/techcrunch-logo-white.png',
    alt: 'TechCrunch Logo',
    link: 'https://techcrunch.com/2024/07/03/altrove-uses-ai-models-and-lab-automation-to-create-new-materials/',
  },
  {
    logo: 'https://createsiteai.b-cdn.net/static_website/altrove/techeu.png',
    alt: 'Tech.eu Logo',
    link: 'https://tech.eu/2024/07/03/altrove-raises-eur37m-for-rare-earth-alternatives',
  },
  {
    logo: 'https://createsiteai.b-cdn.net/static_website/altrove/logo-white_les_echos.png',
    alt: 'Les Echos Logo',
    link: 'https://www.lesechos.fr/start-up/ecosysteme/ces-plateformes-qui-dopent-la-creation-de-materiaux-grace-a-lia-2105458',
  },
  {
    logo: 'https://createsiteai.b-cdn.net/static_website/altrove/bfm_blanc.png',
    alt: 'BFMTV Logo',
    link: 'https://www.bfmtv.com/economie/altrove-des-alternatives-aux-metaux-rares_VN-202407030181.html',
  },
  {
    logo: 'https://createsiteai.b-cdn.net/static_website/altrove/631882965f30c8ff9b70d521_Europe_1_logo.png',
    alt: 'Europe1 Logo',
    link: 'https://www.europe1.fr/emissions/initiative-a-impact/altrove-la-decouverte-de-nouveaux-materiaux-grace-a-lia-4257117',
  },
];

const AsSeenOn: React.FC = () => {
  return (
    <section className="bg-white dark:bg-black py-12 px-4 mobile:px-6 laptop:px-24 desktop:px-24">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-black dark:text-white mb-8">
          Seen On
        </h2>
        <div className="flex flex-wrap justify-center items-center gap-8 laptop:gap-12 desktop:gap-16">
          {newsItems.map((item, index) => (
            <a
              key={index}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className={cn(
                "transition-transform duration-300 ease-in-out",
                "hover:scale-110"
              )}
              data-umami-event={`click_seen_on_${item.alt.replace(' Logo', '').toLowerCase()}`}
            >
              <LazyImage
                src={item.logo}
                alt={item.alt}
                className={cn(
                  "h-12 object-contain",
                  item.alt === 'Les Echos Logo' ? 'les-echos-logo' : '',
                  item.alt === 'BFMTV Logo' ? 'bfmtv-logo' : '',
                  item.alt === 'TechCrunch Logo' ? 'techcrunch-logo' : '',
                  item.alt === 'Tech.eu Logo' ? 'techeu-logo' : ''
                )}
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AsSeenOn;