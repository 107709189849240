import React, { memo } from "react";
import { cn } from "../../utils/cn";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

const NavbarItem = memo(({ label, href, onClick }) => {
  if (href.startsWith("http")) {
    return (
      <li className="relative group">
        <a
          href={href}
          className={cn("mobile-text-lg text-black dark:text-white")}
          onClick={(e) => {
            e.preventDefault();
            onClick(href);
          }}
          target="_blank"
          rel="noopener noreferrer"
          data-umami-event={`navbar_mobile_${label.toLowerCase().replace(/\s+/g, '_')}_click`}
        >
          {label}
          <span
            className={cn(
              "absolute bottom-0 left-1/2 w-0 h-0.5 bg-white transition-all duration-300 ease-in-out group-hover:w-full group-hover:left-0"
            )}
          ></span>
        </a>
      </li>
    );
  }

  return (
    <li className="relative group">
      <Link
        to={href}
        className={cn("mobile-text-lg text-black dark:text-white")}
        data-umami-event={`navbar_mobile_${label.toLowerCase().replace(/\s+/g, '_')}_click`}
      >
        {label}
        <span
          className={cn(
            "absolute bottom-0 left-1/2 w-0 h-0.5 bg-white transition-all duration-300 ease-in-out group-hover:w-full group-hover:left-0"
          )}
        ></span>
      </Link>
    </li>
  );
});

export default function MobileNavbar({
  isOpen,
  toggleMenu,
  navItems,
  handleNavItemClick,
}) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="tablet:hidden laptop:hidden desktop:hidden block bg-white dark:bg-black py-4"
        >
          <ul className="uppercase flex flex-col space-y-6">
            {navItems.map((item, index) => (
              <NavbarItem
                key={index}
                label={item.label}
                href={item.href}
                onClick={() => {
                  toggleMenu();
                  handleNavItemClick(item.href);
                }}
              />
            ))}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
}