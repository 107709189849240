import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import ContactForm from './ContactForm';

interface ExpandableContactFormProps {
  riskTitle: string;
  isOpen: boolean;
  onToggle: () => void;
}

export const ExpandableContactForm: React.FC<ExpandableContactFormProps> = ({ riskTitle, isOpen, onToggle }) => {
  const eventName = `case_study_get_in_touch_click_${riskTitle.toLowerCase().replace(/\s+/g, '_')}`;

  return (
    <div>
      <button
        onClick={onToggle}
        className="text-white transition-colors duration-200 text-sm flex items-center justify-end w-full"
        data-umami-event={eventName}
      >
        {isOpen ? 'Close' : 'Get in Touch'}
        {isOpen ? <FaAngleUp className="ml-1" /> : <FaAngleDown className="ml-1" />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <ContactForm riskTitle={riskTitle} eventName={eventName} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};