import {
  BrowserRouter as _BrowserRouter,
  Link as _Link,
  BrowserRouterProps,
  LinkProps,
} from "node_modules/react-router-dom";
import React from "react";

export * from "node_modules/react-router-dom";

export const BrowserRouter = ({ children, ...props }: BrowserRouterProps) => {
  return <_BrowserRouter {...props}>{children}</_BrowserRouter>;
};

export const Link = ({
  children,
  "data-umami-event": event,
  onClick,
  to,
  ...props
}: LinkProps & { "data-umami-event"?: string }) => {
  return (
    <_Link
      to={to}
      onClick={(e) => {
        onClick?.(e);
        if (window.umami && event) {
          window.umami.track(event);
        }
      }}
      {...props}
    >
      {children}
    </_Link>
  );
};
