import React from 'react';
import { cn } from '../../../utils/cn';

interface StatusMessageProps {
  status: {
    type: 'success' | 'error';
    message: string;
  };
}

const StatusMessage: React.FC<StatusMessageProps> = ({ status }) => (
  <div
    className={cn(
      'mt-4 font-bold text-lg text-center flex items-center justify-center p-4 rounded-md',
      status.type === 'success'
        ? 'bg-green-500 text-white'
        : 'bg-red-500 text-white'
    )}
  >
    {status.type === 'success' && (
      <svg className="mr-2 h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
    )}
    {status.type === 'success' ? 'Thanks, we will get back to you soon!' : status.message}
  </div>
);

export default StatusMessage;