import { createContext, useContext, useState } from "react";

interface MineralScaleContextValue {
  mineralScale: number;
  setMineralScale: (scale: number) => void;
}

const MineralScaleContext = createContext<MineralScaleContextValue>({
  mineralScale: 6,
  setMineralScale: () => {},
});

export const MineralScaleProvider = ({ children }) => {
  const [mineralScale, setMineralScale] = useState(6);

  return (
    <MineralScaleContext.Provider value={{ mineralScale, setMineralScale }}>
      {children}
    </MineralScaleContext.Provider>
  );
};

export const useMineralScale = () => useContext(MineralScaleContext);
