import { memo, useMemo } from "react";
import { cn } from "../../utils/cn";
import CenteredTabbedContent from "../CenteredTabbedContent";
import { tabs } from "./tabs";

const ManufactureContent = memo(() => {
  const centeredTabbedContentMemo = useMemo(
    () => <CenteredTabbedContent tabs={tabs} sectionName="manufacture" />,
    []
  );

  return (
    <div className="mobile:w-full tablet:w-full laptop:w-1/2 desktop:w-1/2 text-left mobile:mt-8 tablet:mt-8">
      <h2 className="text-2xl laptop:text-4xl desktop:text-4xl font-bold text-black dark:text-white mb-4 max-w-[1300px] border-b-2 border-black dark:border-white pb-2">
        Manufacture
      </h2>
      <p className="text-lg laptop:text-xl desktop:text-xl font-normal text-neutral-700 dark:text-neutral-200 mb-8">
        We work with you to produce the volume of materials you need.
      </p>
      {centeredTabbedContentMemo}
      <button
        className={cn(
          "uppercase bg-white no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-lg p-px desktop-text-xl font-semibold leading-6 text-black inline-block transition-colors duration-1000 hover:outline hover:outline-2 hover:outline-white hover:bg-black mt-8"
        )}
        onClick={() =>
          window.open("https://form.typeform.com/to/b3prjMmj?typeform-source=0b7rkp1z8gd.typeform.com", "_blank")
        }
        data-umami-event="click_explore_materials_challenges_manufacture"
      >
        <div
          className={cn(
            "relative flex space-x-4 items-center z-10 rounded-lg bg-white py-3 px-6 ring-1 ring-black/10 transition-colors duration-1000 group-hover:bg-black group-hover:text-white"
          )}
        >
          <span>{`Let's explore your materials challenges`}</span>
        </div>
      </button>
      <p className="text-base laptop:text-lg desktop:text-lg font-normal text-neutral-700 dark:text-neutral-200 mt-8">
        First results in weeks, not years.
      </p>
    </div>
  );
});

export default ManufactureContent;
