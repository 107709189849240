import React from 'react';
import { cn } from "../../utils/cn";
import useFormSubmission from './useFormSubmission';

interface ContactFormProps {
  riskTitle: string;
  eventName: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ riskTitle, eventName }) => {
  const { email, isSubmitting, submitStatus, handleChange, handleSubmit } = useFormSubmission(riskTitle);

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-4 px-2 sm:px-4 py-4 sm:py-4">
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="mt-1 block w-full rounded-md border-gray-600 bg-white text-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-3 py-2"
          value={email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <button
          type="submit"
          className={cn(
            "uppercase bg-white no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-lg p-px text-sm font-semibold leading-6 text-black inline-block transition-colors duration-1000 hover:outline hover:outline-2 hover:outline-white hover:bg-black",
            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
          )}
          disabled={isSubmitting}
          data-umami-event={`${eventName}_submit_form`}
        >
          <div
            className={cn(
              "relative flex space-x-4 items-center z-10 rounded-lg bg-white py-2 px-4 ring-1 ring-black/10 transition-colors duration-1000 group-hover:bg-black group-hover:text-white"
            )}
          >
            <span>{isSubmitting ? "Submitting..." : "Submit"}</span>
          </div>
        </button>
      </div>
      {submitStatus && (
        <div
          className={cn(
            "mt-2 text-base flex items-center p-3 rounded-md",
            submitStatus.type === "success"
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          )}
        >
          {submitStatus.type === "success" && (
            <svg className="mr-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
          )}
          {submitStatus.type === "success" ? "Thanks, we will get back to you soon!" : submitStatus.message}
        </div>
      )}
    </form>
  );
};

export default ContactForm;