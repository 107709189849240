import React, { memo } from "react";
import { cn } from "../../utils/cn";
import { Link } from "react-router-dom";

const NavbarItem = memo(({ label, href, onClick }) => {
  if (href.startsWith("http")) {
    return (
      <li className="relative group">
        <a
          href={href}
          className={cn("text-lg text-black dark:text-white")}
          onClick={(e) => {
            e.preventDefault();
            onClick(href);
          }}
          target="_blank"
          rel="noopener noreferrer"
          data-umami-event={`navbar_desktop_${label.toLowerCase().replace(/\s+/g, '_')}_click`}
        >
          {label}
          <span
            className={cn(
              "absolute bottom-0 left-1/2 w-0 h-0.5 bg-white transition-all duration-300 ease-in-out group-hover:w-full group-hover:left-0"
            )}
          ></span>
        </a>
      </li>
    );
  }

  return (
    <li className="relative group">
      <Link
        to={href}
        className={cn("text-lg text-black dark:text-white")}
        data-umami-event={`navbar_desktop_${label.toLowerCase().replace(/\s+/g, '_')}_click`}
      >
        {label}
        <span
          className={cn(
            "absolute bottom-0 left-1/2 w-0 h-0.5 bg-white transition-all duration-300 ease-in-out group-hover:w-full group-hover:left-0"
          )}
        ></span>
      </Link>
    </li>
  );
});

export default function DesktopNavbar({
  navItems,
  handleNavItemClick,
}) {
  return (
    <div className="tablet:block laptop:block desktop:block hidden">
      <ul className="uppercase flex space-x-12">
        {navItems.map((item, index) => (
          <NavbarItem
            key={index}
            label={item.label}
            href={item.href}
            onClick={() => handleNavItemClick(item.href)}
          />
        ))}
      </ul>
    </div>
  );
}