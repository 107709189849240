import React from "react";
import { cn } from "../utils/cn";
import CustomScrollbar from "./CustomScrollbar";

interface CenteredTabContentProps {
  content: React.ReactNode;
  maxHeight: number;
}

const CenteredTabContent: React.FC<CenteredTabContentProps> = ({ content }) => {
  return (
    <div
      className={cn(
        "flex justify-center items-center",
        "h-[130px] mobile:h-[130px] tablet:h-[130px] laptop:h-[130px] desktop:h-[130px]"
      )}
    >
      <CustomScrollbar>
        <div className="py-4">
          <div className="flex justify-center">
            <p className="text-base laptop:text-base desktop:text-base font-normal text-neutral-300 dark:text-neutral-300 max-w-[600px]">
              {content}
            </p>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default CenteredTabContent;
