import { RefObject, forwardRef, useMemo } from "react";
import { useScreenSize } from "../../hooks/useScreenSize";
import { cn } from "../../utils/cn";
import MaterialsContent from "./MaterialsContent";

interface MaterialsSectionProps {
  handleClick?: () => void;
  designRef: RefObject<HTMLElement>;
  synthesizeRef: RefObject<HTMLElement>;
  manufactureRef: RefObject<HTMLElement>;
}

const MaterialsSection = forwardRef<HTMLDivElement, MaterialsSectionProps>(
  ({ handleClick, designRef, synthesizeRef, manufactureRef }, ref) => {
    const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();

    const materialsContentMemo = useMemo(
      () => (
        <MaterialsContent
          designRef={designRef}
          synthesizeRef={synthesizeRef}
          manufactureRef={manufactureRef}
        />
      ),
      [handleClick, designRef, synthesizeRef, manufactureRef]
    );

    return (
      <div
        id="materials-section"
        className={cn(
          "bg-white dark:bg-black py-12 px-4 mobile:px-6 laptop:px-24 desktop:px-24 flex items-center justify-center mt-20 mb-20",
          isMobile
            ? "h-[calc(100vh-120px)]"
            : isTablet
            ? "h-[calc(100vh-120px)]"
            : isLaptop
            ? "h-[calc(100vh-120px)]"
            : isDesktop
            ? "h-[calc(100vh-120px)]"
            : ""
        )}
        ref={ref}
      >
        <div className="flex flex-col justify-center">
          {materialsContentMemo}
        </div>
      </div>
    );
  }
);

export default MaterialsSection;
