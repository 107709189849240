import { useState } from 'react';
import { useFormspark } from "@formspark/use-formspark";
import { FORMSPARK_FORM_ID } from './constants';

const useFormSubmission = (riskTitle: string) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const [submit] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      await submit({ email, riskTitle });
      setSubmitStatus({ type: 'success', message: 'Form submitted successfully!' });
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus({ type: 'error', message: 'An error occurred. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return { email, isSubmitting, submitStatus, handleChange, handleSubmit };
};

export default useFormSubmission;