import React from "react";
import { cn } from "../../../utils/cn";

interface CTAButtonProps {
  onClick: () => void;
}

const CTAButton: React.FC<CTAButtonProps> = ({ onClick }) => (
  <button
    className={cn(
      "uppercase bg-white no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-lg p-px desktop-text-xl font-semibold leading-6 text-black inline-block transition-colors duration-1000 hover:outline hover:outline-2 hover:outline-white hover:bg-black"
    )}
    onClick={onClick}
    data-umami-event="click_find_alternative_materials"
  >
    <div
      className={cn(
        "relative flex space-x-4 items-center z-10 rounded-lg bg-white py-3 px-6 ring-1 ring-black/10 transition-colors duration-1000 group-hover:bg-black group-hover:text-white"
      )}
    >
      <span>{`FIND ALTERNATIVE MATERIALS`}</span>
    </div>
  </button>
);

export default CTAButton;