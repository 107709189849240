import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MineralScaleProvider } from "./context/MineralScaleContext";

import Home from "./pages/Home";
import CaseStudiesPage from "./pages/CaseStudiesPage";
import ContactUsPage from "./pages/ContactUsPage";

function App() {
  return (
    <MineralScaleProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/case-studies" element={<CaseStudiesPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
        </Routes>
      </Router>
    </MineralScaleProvider>
  );
}

export default App;
