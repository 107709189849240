import { useRef } from "react";
import DesignSection from "../components/DesignSection";
import HeroSection from "../components/HeroSection";
import ManufactureSection from "../components/ManufactureSection";
import MaterialsSection from "../components/MaterialsSection";
import SynthesizeSection from "../components/SynthesizeSection";
import Footer from "../components/ui/Footer";
import Navbar from "../components/ui/Navbar";
import AsSeenOn from "../components/AsSeenOn";

function Home() {
  const materialsRef = useRef(null);
  const designRef = useRef(null);
  const synthesizeRef = useRef(null);
  const manufactureRef = useRef(null);

  return (
    <div className="flex flex-col min-h-screen dark:bg-black bg-white">
      <Navbar />
      <div className="mt-24">
        <HeroSection materialsRef={materialsRef} />
        <MaterialsSection
          ref={materialsRef}
          designRef={designRef}
          synthesizeRef={synthesizeRef}
          manufactureRef={manufactureRef}
        />
        <DesignSection ref={designRef} />
        <SynthesizeSection ref={synthesizeRef} designRef={designRef} />
        <ManufactureSection ref={manufactureRef} />
        <AsSeenOn />
        <Footer />
      </div>
    </div>
  );
}

export default Home;