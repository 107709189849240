import React, { forwardRef } from 'react';
import { cn } from '../../utils/cn';
import { Tab } from './types';

interface TabButtonProps {
  tab: Tab;
  index: number;
  activeTab: number;
  handleTabClick: (index: number) => void;
  sectionName: string;
}

const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  ({ tab, index, activeTab, handleTabClick, sectionName }, ref) => {
    return (
      <button
        className={cn(
          'text-base font-bold py-2 px-4 transition-colors duration-300 rounded-t-lg cursor-pointer',
          activeTab === index
            ? 'bg-[#1f1f1f] text-white'
            : 'text-neutral-500 dark:text-neutral-400 hover:text-white'
        )}
        onClick={() => handleTabClick(index)}
        ref={ref}
        aria-label={tab.title}
        data-umami-event={`${sectionName}_tab_click_${tab.title.toLowerCase().replace(/\s+/g, '_')}`}
      >
        {tab.title}
      </button>
    );
  }
);

export default TabButton;