import React, { forwardRef, useState } from "react";
import { cn } from "../../utils/cn";
import RiskCard from "./RiskCard";
import filteredRisks from "./filteredRisks.json";

const CaseStudiesSection = forwardRef<HTMLDivElement, { className?: string }>(({ className }, ref) => {
  const [openFormIndex, setOpenFormIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenFormIndex(openFormIndex === index ? null : index);
  };

  return (
    <div
      id="case-studies-section"
      className={cn("bg-white dark:bg-black py-4", className)}
      ref={ref}
    >
      <div className="grid grid-cols-1 mobile:grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-3 gap-4">
        {filteredRisks.map((risk, index) => (
          <RiskCard 
            key={index} 
            title={risk.title} 
            content={risk.content}
            isOpen={openFormIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </div>
  );
});

export default CaseStudiesSection;