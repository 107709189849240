import React, { memo } from "react";
import CTAButton from "./CTAButton";
import MotionSpan from "./MotionSpan";

interface MaterialsContentProps {
  designRef: React.RefObject<HTMLElement>;
  synthesizeRef: React.RefObject<HTMLElement>;
  manufactureRef: React.RefObject<HTMLElement>;
}

const MaterialsContent: React.FC<MaterialsContentProps> = memo(
  ({ designRef, synthesizeRef, manufactureRef }) => {
    const handleDesignClick = () => {
      designRef.current &&
        window.scrollTo({
          top: designRef.current.offsetTop - 60,
          behavior: "smooth",
        });
    };

    const handleSynthesizeClick = () => {
      synthesizeRef.current &&
        window.scrollTo({
          top: synthesizeRef.current.offsetTop - 60,
          behavior: "smooth",
        });
    };

    const handleManufactureClick = () => {
      manufactureRef.current &&
        window.scrollTo({
          top: manufactureRef.current.offsetTop - 60,
          behavior: "smooth",
        });
    };

    const handleCTAClick = () => {
      window.open("https://form.typeform.com/to/b3prjMmj?typeform-source=0b7rkp1z8gd.typeform.com", "_blank");
    };

    return (
      <div className="w-full">
        <h2 className="text-3xl laptop:text-5xl desktop:text-7xl font-medium text-black dark:text-white mb-4 text-left">
          We{" "}
          <MotionSpan
            text="design"
            color="#b3d9ff"
            onClick={handleDesignClick}
            dataUmamiEvent="click_design_section"
          />
          ,{" "}
          <MotionSpan
            text="synthesize"
            color="#b3ffb3"
            onClick={handleSynthesizeClick}
            dataUmamiEvent="click_synthesize_section"
          />{" "}
          and{" "}
          <MotionSpan
            text="manufacture"
            color="#ffb3b3"
            onClick={handleManufactureClick}
            dataUmamiEvent="click_manufacture_section"
          />{" "}
          alternatives to your critical materials, at scale.
        </h2>
        <p className="text-lg laptop:text-xl desktop:text-xl font-normal text-neutral-700 dark:text-neutral-200 mb-8">
          We de-risk your critical materials sourcing challenges by creating
          sustainable alternatives, fast.
        </p>
        <CTAButton onClick={handleCTAClick} />
        <p className="text-base laptop:text-lg desktop:text-lg font-normal text-neutral-700 dark:text-neutral-200 mt-8">
          First results in weeks, not years.
        </p>
      </div>
    );
  }
);

export default MaterialsContent;
