import { motion } from "framer-motion";
import React from "react";

interface MotionSpanProps {
  text: string;
  color: string;
  onClick: () => void;
  dataUmamiEvent: string;
}

const MotionSpan: React.FC<MotionSpanProps> = ({
  text,
  color,
  onClick,
  dataUmamiEvent,
}) => (
  <motion.span
    whileHover={{
      y: -5,
      cursor: "pointer",
    }}
    transition={{ duration: 0.3 }}
    className="inline-block relative"
    style={{
      color: color,
      backgroundImage: `linear-gradient(to right, ${color}, ${color})`,
      backgroundSize: "100% 2px",
      backgroundPosition: "0 100%",
      backgroundRepeat: "no-repeat",
    }}
    onClick={onClick}
    data-umami-event={dataUmamiEvent}
  >
    {text}
  </motion.span>
);

export default MotionSpan;
