import { memo, useMemo } from "react";
import { FaAngleRight } from "react-icons/fa";
import { cn } from "../utils/cn";

interface HeroContentProps {
  materialsRef: React.RefObject<HTMLDivElement>;
}

const HeroContent = memo<HeroContentProps>(({ materialsRef }) => (
  <div className="flex-1 mb-0 mobile:mb-4">
    <h2 className="text-left text-3xl laptop:text-6xl desktop:text-8xl font-bold text-black dark:text-white">
      Creating the materials of tomorrow, today.
    </h2>
    <p className="text-left text-lg laptop:text-lg desktop:text-lg font-normal text-neutral-700 dark:text-neutral-200 max-w-lg mt-4">
      We are on a mission to enable more efficient and faster inorganic
      materials discovery for the technologies of tomorrow.
    </p>
    <button
      className={cn(
        "uppercase mt-8 bg-white no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-lg p-px desktop-text-xl font-semibold leading-6 text-black inline-block transition-colors duration-1000 hover:outline hover:outline-2 hover:outline-white hover:bg-black"
      )}
      onClick={() => {
        materialsRef.current &&
          window.scrollTo({
            top: materialsRef.current.offsetTop - 60,
            behavior: "smooth",
          });
      }}
      aria-label="Discover Altrove"
      data-umami-event="click_discover_altrove"
    >
      <div
        className={cn(
          "relative flex space-x-4 items-center z-10 rounded-lg bg-white py-3 px-6 ring-1 ring-black/10 transition-colors duration-1000 group-hover:bg-black group-hover:text-white"
        )}
      >
        <span>{`Discover Altrove`}</span>
        <FaAngleRight
          className={cn(
            "group-hover:text-white transition-transform duration-1000 group-hover:rotate-90"
          )}
        />
      </div>
    </button>
  </div>
));

export default function HeroSection({
  materialsRef,
}: {
  materialsRef: React.RefObject<HTMLDivElement>;
}) {
  const heroContentMemo = useMemo(
    () => <HeroContent materialsRef={materialsRef} />,
    [materialsRef]
  );

  return (
    <div
      id="hero-section"
      className="flex flex-col laptop:flex-row desktop:flex-row items-center justify-center h-[90vh] relative w-full mobile:h-[600px]"
    >
      <div className="mx-auto w-full relative h-full flex flex-col laptop:flex-row desktop:flex-row items-center justify-start px-4 mobile:px-6 laptop:px-24 desktop:px-24">
        {heroContentMemo}
        <div className="flex-1 h-[350px] laptop:h-[600px] desktop:h-[600px] w-full laptop:w-[600px] desktop:w-[600px] flex justify-center items-center mobile:mt-8 mobile:mb-8 mobile:ml-0 mobile:mr-0 tablet:mt-8 tablet:mb-8 tablet:ml-0 tablet:mr-0">
          <img
            src="https://createsiteai.b-cdn.net/static_website/altrove/blue_mineral.png"
            alt="Mineral"
            className="w-full h-full object-contain laptop:scale-75"
          />
        </div>
      </div>
    </div>
  );
}
