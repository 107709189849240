import React, { memo } from 'react';
import { cn } from '../utils/cn';

const CustomScrollbar = memo(({ children }) => {
  return (
    <div
      className={cn(
        'overflow-y-auto',
        'scrollbar-thin',
        'scrollbar-thumb-[var(--scrollbar-thumb-color)]',
        'scrollbar-track-[var(--scrollbar-track-color)]',
        'scrollbar-thumb-rounded-[var(--scrollbar-thumb-radius)]',
        'scrollbar-track-rounded-[var(--scrollbar-track-radius)]',
        'scrollbar-w-[var(--scrollbar-width)]',
        'scrollbar-h-[var(--scrollbar-height)]'
      )}
    >
      {children}
    </div>
  );
});

export default CustomScrollbar;