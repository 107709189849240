import React from "react";
import { FaLinkedin } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="bg-white dark:bg-black py-12 px-4 mobile:px-6 laptop:px-24 desktop:px-24">
      <div className="flex flex-col laptop:flex-row desktop:flex-row items-center justify-between">
        <div className="flex flex-1 justify-start items-center space-x-4">
          <a
            href="https://www.linkedin.com/company/altrove-ai"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Altrove LinkedIn"
            className="text-black dark:text-white transition-colors duration-300"
            data-umami-event="click_linkedin_footer"
          >
            <FaLinkedin size={24} />
          </a>
        </div>
        <div className="flex-1 flex justify-end text-black dark:text-white mt-4 laptop:mt-0 desktop:mt-0">
          Altrove © {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}