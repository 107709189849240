import React from 'react';
import { cn } from '../../utils/cn';

interface TabIndicatorProps {
  index: number;
  tabIndicatorIndex: number;
  handleTabClick: (index: number) => void;
  sectionName: string;
}

const TabIndicator: React.FC<TabIndicatorProps> = ({
  index,
  tabIndicatorIndex,
  handleTabClick,
  sectionName,
}) => {
  return (
    <div
      className={cn(
        'w-3 h-3 rounded-full mx-1 transition-all duration-300 cursor-pointer',
        index === tabIndicatorIndex
          ? 'bg-white'
          : 'bg-neutral-500 dark:bg-neutral-400 hover:bg-white'
      )}
      onClick={() => handleTabClick(index)}
      data-umami-event={`${sectionName}_tab_indicator_${index + 1}`}
    />
  );
};

export default TabIndicator;