import { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { cn } from "../../utils/cn";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { label: "Case Studies", href: "/case-studies" },
    { label: "Contact us", href: "/contact-us" },
    {
      label: "Careers",
      href: "https://www.linkedin.com/company/altrove-ai/jobs",
    },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleNavItemClick = (href) => {
    if (href.startsWith("http")) {
      window.open(href, "_blank", "noopener,noreferrer");
    }
    setIsOpen(false);
  };

  return (
    <nav className="bg-white dark:bg-black fixed top-0 left-0 right-0 z-50 px-4 mobile:px-6 tablet:px-24 laptop:px-24 desktop:px-24">
      <div className="flex items-center justify-between py-4">
        <Link
          to="/"
          className="text-4xl font-normal text-black dark:text-white lexend-400 cursor-pointer relative group"
          data-umami-event="navbar_logo_click"
        >
          altrove
          <span
            className={cn(
              "absolute bottom-0 left-1/2 w-0 h-0.5 bg-white transition-all duration-300 ease-in-out group-hover:w-full group-hover:left-0"
            )}
          ></span>
        </Link>
        <DesktopNavbar
          navItems={navItems}
          handleNavItemClick={handleNavItemClick}
        />
        <div className="tablet:hidden laptop:hidden desktop:hidden block flex items-center">
          <button
            onClick={toggleMenu}
            aria-label={isOpen ? "Close Menu" : "Open Menu"}
            data-umami-event={
              isOpen ? "navbar_close_mobile_menu" : "navbar_open_mobile_menu"
            }
          >
            {isOpen ? (
              <FaTimes className="text-black dark:text-white text-2xl" />
            ) : (
              <FaBars className="text-black dark:text-white text-2xl" />
            )}
          </button>
        </div>
      </div>
      <MobileNavbar
        isOpen={isOpen}
        toggleMenu={toggleMenu}
        navItems={navItems}
        handleNavItemClick={handleNavItemClick}
      />
    </nav>
  );
}
