import React from 'react';
import { cn } from '../../../utils/cn';

interface SubmitButtonProps {
  isSubmitting: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isSubmitting }) => (
  <div className="flex justify-center">
    <button
      type="submit"
      className={cn(
        'uppercase bg-white no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-lg p-px desktop-text-2xl font-semibold leading-6 text-black inline-block transition-colors duration-1000 hover:outline hover:outline-2 hover:outline-white hover:bg-black',
        isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
      )}
      disabled={isSubmitting}
      data-umami-event="click_submit_contact_form"
    >
      <div
        className={cn(
          'relative flex space-x-4 items-center z-10 rounded-lg bg-white py-3 px-6 ring-1 ring-black/10 transition-colors duration-1000 group-hover:bg-black group-hover:text-white'
        )}
      >
        <span>{isSubmitting ? 'Submitting...' : 'Submit'}</span>
      </div>
    </button>
  </div>
);

export default SubmitButton;