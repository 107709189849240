export const designTabs = [
  {
    title: "Technical Specs",
    content:
      "Specify your technical requirements (e.g. heat resistance, weight, Curie temperature, etc.).",
  },
  {
    title: "Price Range",
    content: "Let us know your budget requirements.",
  },
  {
    title: "Processing Requirements",
    content: "Provide details on your manufacturing process needs.",
  },
  {
    title: "Additional Requests",
    content: "Mention any other specific requirements (e.g. 'no rare earths').",
  },
];
